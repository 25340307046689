import { postContactData } from 'api/contactApi'

const contactData = {
    state: ()=>({
        // ContactData:[],
    }),

    mutations:{
        setContactData(state, payload){
            state.contactData = payload
        },
    },

    getters:{

    },

    actions:{
        async doPostContactData({commit}, obj) {
            //console.log(obj)
            return await postContactData(obj)
        }
    }
}

export default contactData