<template>
 
    <gmap-map 
      :center="{ lat: lat, 
          lng: lng }"
      :zoom="15"
      :style="{
        width: width,
        height: height
      }"
    >
      <gmap-marker
        :position="{ lat: lat, 
          lng: lng  }"
      ></gmap-marker>
    </gmap-map>
        
</template>

<script>
export default {
  name: "Maps",
  props:{
    width:{
      type:String
    },
    height:{
      type:String
    },
    lat:{
        type:Number
      },
      lng:{
        type:Number
      }    
  }  
};
</script>

<style lang="scss" scoped>
@import './Maps.scss';
</style>
