import Api from 'utils/Api'

export function postContactData(obj) {
    return Api
        .post(
            '/LeadsMS/contact/',
            {
                "name": obj.name,
                "description": obj.description,
                "email": obj.email,
                "state": obj.state,
                "message": obj.message,
                "phone": obj.phone,
                "channel": obj.channel,
            }
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}   